import React from 'react';

const NextButton = ({ rotate }) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: rotate ? 'rotate(180deg)' : 'none' }}
  >
    <g opacity="0.7" filter="url(#filter0_b_8432_71872)">
      <rect
        x="56"
        y="56"
        width="56"
        height="56"
        rx="28"
        transform="rotate(-180 56 56)"
        fill="#FCFCFD"
      />
      <path
        d="M25 21L32 28L25 35"
        stroke="#182230"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_8432_71872"
        x="-8"
        y="-8"
        width="72"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8432_71872"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8432_71872"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default NextButton;