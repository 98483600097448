import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import AppStore from "../../assets/img/header/app-store-download.svg";
import GooglePlay from "../../assets/img/header/google-play-download.svg";

import Instagram from "../../assets/img/footer/Instagram.svg"
import Tiktok from "../../assets/img/footer/Tiktok.svg"
import X from "../../assets/img/footer/X.svg"
import LinkedIn from "../../assets/img/footer/LinkedIn.svg"
import Discord from "../../assets/img/footer/Discord.svg"

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Container className="container flexColumn">
      <FooterWrapper className="flexRow">
        <FooterLeft className="flexColumn">
          <p className="medium" style={{fontFamily: 'Rubik', marginTop: '20px', marginBottom: '10px'}}>Download</p>
          <DownloadWrapper>
            <Clickable className="pointer">
              <Link to="###" smooth={true} duration={1000}>
                <Img
                  src={AppStore}
                  alt="AppStore"
                />
              </Link>
            </Clickable>
            <Clickable className="pointer">
              <Link to="###" smooth={true} duration={1000}>
                <Img
                  src={GooglePlay}
                  alt="GooglePlay"
                />
              </Link>
            </Clickable>
          </DownloadWrapper>

          <div>
            <a href="https://www.instagram.com/syncso?igsh=bDV1c2V0b3VneXgx" target="_blank" rel="noopener noreferrer"><Icon src={Instagram} alt="Instagram"/></a>
            <a href="https://www.tiktok.com/@syncso_?_t=8rrvTlnLwGE&_r=1" target="_blank" rel="noopener noreferrer"><Icon src={Tiktok} alt="Tiktok"/></a>
            <a href="###" target="_blank" rel="noopener noreferrer"><Icon src={X} alt="X"/></a>
            <a href="https://www.linkedin.com/company/syncso/" target="_blank" rel="noopener noreferrer"><Icon src={LinkedIn} alt="LinkedIn"/></a>
            <a href="https://discord.gg/xMjQ3jWk" target="_blank" rel="noopener noreferrer"><Icon src={Discord} alt="Discord"/></a>
          </div>
        </FooterLeft>

        <FooterRight>
          <LinkWrapper>
            <a href="###"><FooterText>Terms of use</FooterText></a>
            <a href="/privacy-policy"><FooterText>Privacy</FooterText></a>
            <a href="###"><FooterText>Community Guideline</FooterText></a>
          </LinkWrapper>
          <LinkWrapper>
            <a href="###"><FooterText>Join us</FooterText></a>
            <a href="###"><FooterText>Contact</FooterText></a>
          </LinkWrapper>
          <LinkWrapperPlaceholder></LinkWrapperPlaceholder>
        </FooterRight>
      </FooterWrapper>

      <CopyRightWrapper className="flexCenter">
              <CopyRight className="font16" style={{color: '#475467'}}>
                © {getCurrentYear()} SyncSo All Right Reserved.
              </CopyRight>
      </CopyRightWrapper>
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
`;

const FooterWrapper = styled.div`
  margin: 50px 50px 10px 50px;
  @media (max-width: 1248px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FooterLeft = styled.div`
  width: 40%;
  @media (max-width: 1248px) {
    width: 100%;
    align-items: center;
  }
`;
const FooterRight = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1248px) {
    width: 100%;
    align-items: center;
  }
`;
const LinkWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1248px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
const LinkWrapperPlaceholder = styled.div`
  width: 30%;
  flex-direction: column;
  @media (max-width: 1248px) {
    display: none;
  }
`;

const FooterText = styled.div`
  color: #475467;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index:10;
  @media (max-width: 1248px) {
    width: 190px;
    text-align: center;
  }
`;

const CopyRightWrapper = styled.div`
  padding: 30px;
  margin: 0px 50px; 
  border-top-width: 2px;
  border-top-color: #F1F7FC;
  border-style: solid;
`;

const CopyRight = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  margin: 0px 0px 30px 0px;
`;
const Clickable = styled.div`
  width: 152px;
  height: 48px;
  margin: 10px 30px 10px 0px;
  position: relative;
  z-index: 10;
  @media (max-width: 1248px) {
    margin: 10px 15px 10px 15px;
  }
`;

const Img = styled.img`
  width: 160px;
  height: auto;
`;

const Icon = styled.img`
  width: 41px;
  height: auto;
  margin-right: 35px;
  position: relative;
  z-index: 10;
  @media (max-width: 1248px) {
    margin: 10px 17.5px 10px 17.5px;
  }
`;

