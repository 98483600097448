import React from "react";
import { Link, animateScroll as Scroll } from 'react-scroll';
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import SyncoIcon from "../../assets/img/header/synco-icon.svg";
import AppStore from "../../assets/img/header/app-store-download.svg";
import GooglePlay from "../../assets/img/header/google-play-download.svg";
import HeaderBG from '../../assets/img/header/header-bg-img-covered.svg';

export default function Header() {
  return (
    <Wrapper id="home" className="container">
      <img src={SyncoIcon} alt="SyncoIcon" style={{position: 'absolute', top:20, left: 40, zIndex: 9}} />
      <LeftSide className="flexColumn flexCenter">
          <HeaderP className="regular font70">
            Share plans<br />Join friends
          </HeaderP>
          <DownloadWrapper>
            <Clickable className="pointer">
              <Link to="###" smooth={true} duration={1000}>
                <img src={AppStore} alt="AppStore" />
              </Link>
            </Clickable>
            {/* <Clickable className="pointer">
              <Link to="###" smooth={true} duration={1000}>
                <img src={GooglePlay} alt="GooglePlay" />
              </Link>
            </Clickable> */}
          </DownloadWrapper>
      </LeftSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  padding-top: 80px;
  width: 100%;
  min-height: 696px;
  // background img
  background-image: url(${HeaderBG});
  background-size: cover;
  background-position: center;
  @media (max-width: 2424px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 2424px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const HeaderP = styled.div`
  // Layout
  max-width: 600px;
  padding: 15px 0 50px 0;
  line-height: 84px;
  // Text
  font-family: 'Poetsen One';
  color: rgba(252, 252, 253, 1);
  @media (max-width: 2424) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const DownloadWrapper = styled.div`
  display: flex
`;
const Clickable = styled.div`
  width: 152px;
  height: 48px;
  margin-right: 10px;
  z-index: 9;
  @media (max-width: 2424) {
    margin: 0 auto;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  width: 100%;
  padding: 0 15%;
  img {
    width: 70%;
    height: auto;
  }
  @media (max-width: 400px) {
    // width: 100%;
    justify-content: center;
    padding: 0;
    margin-bottom: 30px;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 30px;
  border-radius: 20%;
`;