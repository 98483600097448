import React from "react";
import styled from "styled-components";

// Assets
import Section1 from "../../assets/img/main/section1.svg"
import Section2 from "../../assets/img/main/section2.svg"
import Section3 from "../../assets/img/main/section3.svg"

export default function Main() {

    return (<Container className="container flexColumn">
                {/* First Section */}
                <Wrapper className="flexRow">
                    <TextSide className="flexColumn flexSpaceCenter" style={{marginLeft: 80, zIndex: 10}}>
                        <Title>
                            Sync calendar with friends
                        </Title>
                        <SubTitle>
                            Connect your calendar and share your plans with just one click!
                        </SubTitle>
                    </TextSide>

                    <Img src={Section1} alt="Section1" style={{top: -50, left: 500}}/>                                                            
                </Wrapper>

                

                {/* Second Section */}
                <Wrapper className="flexEndCenter">
                    <TextSide className="flexColumn flexSpaceCenter" style={{marginRight: 100, zIndex: 10}}>
                        <Title>
                            What are your friends up to?    
                        </Title>
                        <SubTitle>
                            Check your friends’ schedules of the day and join them
                        </SubTitle>
                    </TextSide>
                    <Img src={Section2} alt="Section2" style={{ top:0,
                                                                left: 50}} />
                </Wrapper>



                {/* Third Section */}
                <Wrapper className="flexRow">
                    <TextSide className="flexColumn flexSpaceCenter" style={{marginLeft: 90, zIndex: 10}}>
                        <Title>
                            Join anyone, anywhere, anytime
                        </Title>
                        <SubTitle>
                            Join the Gen Z movement. Vibe with your tribe!
                        </SubTitle>
                    </TextSide>
                    
                    <Img src={Section3} alt="Section3" style={{ top: -100,
                                                                right: 100 }} />   
                </Wrapper>
            </Container>)

}

const Container = styled.section`
    width: 100%;
`;

const Wrapper = styled.section`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    @media (max-width: 1248px) {
        flex-direction: column;
        align-items: center;
    }
`;

const TextSide = styled.div`
  width: 40%;
  height: 100%;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 1;
//     margin: 50px 0;
//     text-align: center;
//   }
//   @media (max-width: 560px) {
//     margin: 80px 0 50px 0;
//   }
`;

const EmptySide = styled.div`
  width: 60%;
  height: 100%;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 1;
//     margin: 50px 0;
//     text-align: center;
//   }
//   @media (max-width: 560px) {
//     margin: 80px 0 50px 0;
//   }
`;

const Title = styled.div`
  // Layout
  width: 550px;
  margin-top: 300px;
  padding: 10px 50px;
  line-height: 48px;
  // Text
  font-family: 'Poetsen One';
  font-weight: 400;
  font-size: 40px;
  color: #000;
  // Linear Gradient
  background: linear-gradient(-53deg, #A515FD 47%, #2763FF 84%);
  -webkit-background-clip: text; /* Clips the gradient to the text */
  -webkit-text-fill-color: transparent; /* Makes the text itself transparent */
  @media (max-width: 1248px) {
    text-align: center;
    margin-top: 150px;
  }
`;

const SubTitle = styled.div`
  // Layout
  width: 550px;
  padding: 10px 50px;
  line-height: 32px;
  margin-bottom: 300px;
  // Text
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 20px;
  color: #555861;
  @media (max-width: 1248px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;

const Img = styled.img`
    object-fit: 'contain';
    position: absolute;
    z-index: 1;
    width: 800px;
    height: auto;
    @media (max-width: 1248px) {
        position: static;
    }
`;